/**
 * Retrieves the current address of the user.
 * @param storageService - The storage service used to retrieve user information.
 * @returns The current address of the user, or null if no address is found.
 */
import { StorageService } from '@services/storage.service';

export function GetCurrentAddress(storageService: StorageService): string | null {
  const user = storageService.getUser();
  const currentAddress = storageService.getCurrentAddress();

  if (currentAddress) {
    return currentAddress;
  }

  if (user && user.addresses) {
    const defaultAddress = user.addresses.find(address => address.is_default);
    if (defaultAddress && defaultAddress.city) {
      return `${defaultAddress.city}, ${defaultAddress.street} ${defaultAddress.building_number}`;
    }
  }

  return null;
}
